import React from 'react';
import classNames from 'classnames';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import s from './RatingSummarySlot.scss';
import {Cell} from '../Layouts/Cell/Cell';
import {ReviewsSlotIds} from '../../constants';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';

export const RatingSummarySlot: React.FC<ProvidedGlobalProps> = withGlobalProps((props: ProvidedGlobalProps) => {
  const isPluginInstalled = !!props.globals.slots?.[ReviewsSlotIds.ProductPageDetailsSlot1];
  return (
    <Cell className={classNames(isPluginInstalled ? s.spacing : '', s.root)}>
      <SlotsPlaceholder slotId={ReviewsSlotIds.ProductPageDetailsSlot1} />
    </Cell>
  );
});

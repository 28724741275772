import {LayoutConfig} from '../../../types/app-types';
import {Alignment, MarginBottom} from '../../../constants';
import _ from 'lodash';

export const createLayoutConfigWithDefaults = (overrides: Partial<LayoutConfig> = {}): LayoutConfig => {
  const widthConf = {num: 0, unit: 'px'};
  const heightConf = {num: 0, unit: 'px'};
  return _.defaults(overrides, {
    align: Alignment.CENTER,
    dimensions: {mainMedia: {widthConf, heightConf}, thumbnails: {widthConf, heightConf}},
    marginBottom: MarginBottom.MEDIUM,
    swipeToScroll: false,
    withDynamicHeight: false,
    withImageRatio: false,
    withMediaBorder: false,
  });
};

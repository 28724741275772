import React from 'react';
import s from './StackedInfoItem.scss';
import classNames from 'classnames';
import {InfoSectionDescription} from '../../../InfoSectionDescription/InfoSectionDescription';
import {InfoSectionTitle} from '../../../InfoSectionTitle/InfoSectionTitle';
import {InfoSectionLayoutId} from '../../../../../constants';
import {Cell} from '../../../../Layouts/Cell/Cell';

export enum DataHook {
  StackedInfoItem = 'stacked-info-item',
}

export interface StackedInfoItemProps {
  description: string;
  index: number;
  title: string;
  shouldShowDivider: boolean;
}

export class StackedInfoItem extends React.Component<StackedInfoItemProps> {
  public render(): JSX.Element {
    const {description, title, shouldShowDivider, index} = this.props;
    const stackedInfoItemClass = classNames(s.container, {[s.withDivider]: shouldShowDivider});

    return (
      <li className={stackedInfoItemClass} data-hook={DataHook.StackedInfoItem}>
        <Cell className={s.stacked}>
          <InfoSectionTitle theme={InfoSectionLayoutId.Stacked} title={title} />
          <InfoSectionDescription description={description} index={index} />
        </Cell>
      </li>
    );
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import cx from 'classnames';
import {LeftArrow} from '../../../icons/dist';
import s from '../Navigation.scss';
import {NavigationDataHooks} from '../Navigation';
import {useIsAfterHydration} from '../hooks/useIsAfterHydration';

interface ShortBreadcrumbsProps {
  pageName: string;
  href: string;
  isRTL: boolean;
  hideNavigationUrls: boolean;
  t: (string: any, values?: object, fallback?: string) => string;
  shouldRenderAfterHydration: boolean;
}

export const ShortBreadcrumbs: React.FC<ShortBreadcrumbsProps> = (props: ShortBreadcrumbsProps) => {
  const {pageName, href, isRTL, hideNavigationUrls, t, shouldRenderAfterHydration} = props;
  const {isAfterHydration} = useIsAfterHydration();

  const shouldRenderBackToPageLink = shouldRenderAfterHydration
    ? /* istanbul ignore next: hard to test */ isAfterHydration
    : true;

  const iconClass = cx({[s.shortBreadcrumbsRTL]: isRTL});
  return (
    <React.Fragment key={isAfterHydration ? 1 : 0}>
      <a
        href={hideNavigationUrls ? undefined : href}
        data-hook={NavigationDataHooks.ShortBreadcrumbs}
        className={s.shortBreadcrumbs}>
        <LeftArrow className={iconClass} />
        {shouldRenderBackToPageLink && <span>{t('BREADCRUMBS_BACK_TO_PAGE_LINK', {pageName})}</span>}
      </a>
    </React.Fragment>
  );
};

import * as React from 'react';
import {ArrowLeft, ArrowRight} from '../../../../../icons/dist';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import classNames from 'classnames';
import s from './TabArrow.scss';
import {ArrowsDir} from '../../../../../constants';

export enum TabArrowDataHook {
  TabsInfoSection = 'tabs-info-section',
  Body = 'tabs-info-section-body',
  TabArrowLeft = 'tabs-arrow-left',
  TabArrowRight = 'tabs-arrow-right',
}

export interface TabArrowProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  direction: ArrowsDir;
  arrowStyle: {width: number};
  handleKeypressScroll(e: React.KeyboardEvent<HTMLButtonElement>): void;
  handleScrollClick(): void;
}

export const TabArrow: React.FC<TabArrowProps> = withGlobalProps(
  withTranslations('globals.texts')((props: TabArrowProps) => {
    const {direction, arrowStyle, handleKeypressScroll, handleScrollClick, t} = props;
    const iconContainerClass = classNames(
      s.iconContainer,
      {[s.leftArrowButton]: direction === ArrowsDir.LEFT},
      {[s.rightArrowButton]: direction === ArrowsDir.RIGHT}
    );
    const btnClass = classNames(s.icon);
    return (
      <div style={arrowStyle} className={iconContainerClass}>
        <button
          type="button"
          onKeyPress={handleKeypressScroll}
          className={btnClass}
          onClick={handleScrollClick}
          data-hook={direction === ArrowsDir.LEFT ? TabArrowDataHook.TabArrowLeft : TabArrowDataHook.TabArrowRight}
          aria-label={direction === ArrowsDir.LEFT ? t('GALLERY_PREVIOUS') : t('GALLERY_NEXT')}>
          {direction === 'left' ? <ArrowLeft /> : <ArrowRight />}
        </button>
      </div>
    );
  })
);

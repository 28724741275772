import React from 'react';
import cx from 'classnames';
import s from './Cell.scss';

export interface ICell {
  className?: string;
  children?: React.ReactNode;
  dataHook?: string;
  dir?: 'ltr' | 'rtl';
}

export const Cell: React.FunctionComponent<ICell> = ({className, children, dir}) => {
  if (!children) {
    return null;
  }

  const props = {
    className: cx(className, s.cell, 'cell'),
    dir,
  };

  return <div {...props}>{children}</div>;
};

import React from 'react';
import s from './Tab.scss';
import classNames from 'classnames';
import {InfoSectionLayoutId, keyboardEvents} from '../../../../../constants';
import {InfoSectionTitle} from '../../../InfoSectionTitle/InfoSectionTitle';
import {ForwardRefProps, WithRef} from '../../../../../../commons/withRef';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobalProps} from '../../../../../providers/globalPropsProvider';

export enum DataHook {
  Tab = 'collapse-info-item',
}

export interface ITabProps extends Partial<ForwardRefProps>, IProvidedTranslationProps {
  index: number;
  title: string;
  isActive: boolean;
  handelToggleActive(index: number): void;
}

export const Tab: React.FC<ITabProps> = WithRef(
  withGlobalProps(
    withTranslations('globals.texts')((props: ITabProps) => {
      const onToggleActive = () => {
        const {handelToggleActive, index} = props;
        handelToggleActive(index);
      };

      const onKeypressToggleActive = (e: React.KeyboardEvent<HTMLLIElement>) => {
        const {handelToggleActive, index} = props;

        /* istanbul ignore next: todo(ariel): test else */
        if (e.keyCode === keyboardEvents.ENTER.keyCode) {
          handelToggleActive(index);
        } else if (e.keyCode === keyboardEvents.ARROW_RIGHT.keyCode) {
          e.preventDefault();
          e.stopPropagation();
          handelToggleActive(index + 1);
        } else if (e.keyCode === keyboardEvents.ARROW_LEFT.keyCode) {
          e.preventDefault();
          e.stopPropagation();
          handelToggleActive(index - 1);
        }
      };

      const {title, forwardedRef, isActive, t} = props;
      const tabClass = classNames(s.tab);

      return (
        <li
          aria-current={isActive}
          tabIndex={isActive ? 0 : -1}
          className={tabClass}
          onKeyPress={onKeypressToggleActive}
          onKeyDownCapture={onKeypressToggleActive}
          onClick={onToggleActive}
          data-hook={DataHook.Tab}
          ref={forwardedRef}
          role="tab"
          title={t('SR_USE_ARROWS')}>
          <InfoSectionTitle title={title} theme={InfoSectionLayoutId.Tabs} />
        </li>
      );
    })
  )
);

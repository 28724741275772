import {SocialVendor} from '../../constants';
import {ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import {IMediaItem, IProductDTO} from '../../types/app-types';
import {defaultProductMediaImage} from '@wix/wixstores-client-core/dist/es/src/builders/product/mediaItems.builder';
import stylesParams from '../../../components/ProductPage/stylesParams';

export function getDefaultProductMedia(product: IProductDTO): IMediaItem {
  return /* istanbul ignore next: todo: test */ product.media && product.media.length > 0
    ? product.media[0]
    : defaultProductMediaImage;
}

export function shouldRenderVendorFromStyledParams(vendor: SocialVendor, props: ProvidedGlobalProps): boolean {
  const {styles} = props.globals;

  const styledKey = `productPage_socialNetwork${vendor}`;

  return styles.get(stylesParams[styledKey]) as boolean;
}

import React from 'react';
import {InfoSectionLayoutId} from '../../constants';
import {CollapseInfoSection} from './Layouts/CollapseInfoSection/CollapseInfoSection';
import {StackedInfoSection} from './Layouts/StackedInfoLayout/StackedInfoSection';
import {ColumnsInfoSection} from './Layouts/ColumnsInfoLayout/ColumnsInfoSection';
import {TabsInfoSection} from './Layouts/TabsInfoLayout/TabsInfoSection';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {
  geColumnNumberFromGlobalProps,
  geShowDividerFlagFromGlobalProps,
  geTabFontFromGlobalProps,
  getAdditionalInfoFromGlobalProps,
  getDirectionFromGlobalProps,
  getInfoSectionLayoutTypeFromGlobalProps,
  getIsRtlFromGlobalProps,
  getOnActiveBIEventHandler,
} from './InfoSectionLayoutUtils';
import {InfoSectionEvent} from '../../types/app-types';
import {IProductInfo} from '@wix/wixstores-graphql-schema';

export interface InfoSectionProps extends ProvidedGlobalProps {
  infoSectionItems: IProductInfo[];
}

export const InfoSectionLayout: React.FC<ProvidedGlobalProps> = withGlobalProps((props) => {
  const handleBIEvent = (BIEvent: InfoSectionEvent) => {
    const onActive = getOnActiveBIEventHandler(props);
    onActive(BIEvent);
  };

  const renderLayout = () => {
    const layoutId = getInfoSectionLayoutTypeFromGlobalProps(props);
    const infoSectionItems = getAdditionalInfoFromGlobalProps(props);
    const shouldShowDivider = geShowDividerFlagFromGlobalProps(props);
    const numberOfColumns = geColumnNumberFromGlobalProps(props);
    const tabFontStyle = geTabFontFromGlobalProps(props);
    const dir = getDirectionFromGlobalProps(props);
    const isRTL = getIsRtlFromGlobalProps(props);
    const updateLayout = props.globals.updateLayout;
    const infoSectionProps = {
      key: `info-section-${props.globals.product.id}`,
      infoSectionItems,
    };

    switch (layoutId) {
      case InfoSectionLayoutId.Collapse:
        return <CollapseInfoSection {...infoSectionProps} handleBIEvent={handleBIEvent} updateLayout={updateLayout} />;
      case InfoSectionLayoutId.Stacked:
        return <StackedInfoSection infoSectionItems={infoSectionItems} shouldShowDivider={shouldShowDivider} />;
      case InfoSectionLayoutId.Columns:
        return (
          <ColumnsInfoSection
            {...infoSectionProps}
            numberOfColumns={numberOfColumns}
            shouldShowDivider={shouldShowDivider}
            isRTL={isRTL}
          />
        );
      case InfoSectionLayoutId.Tabs:
        return (
          <TabsInfoSection
            {...infoSectionProps}
            handleBIEvent={handleBIEvent}
            tabFontStyle={tabFontStyle}
            direction={dir}
            isRTL={isRTL}
          />
        );
    }
  };

  return <div dir="ltr">{renderLayout()}</div>;
});

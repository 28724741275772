import React from 'react';
import {Navigation} from '../../Navigation/Navigation';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {Cell} from '../Cell/Cell';
import {LayoutComponentProps} from '../../../types/app-types';
import s from './StunningLayout.scss';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {ReviewsSlot} from '../../ReviewsSlot/ReviewsSlot';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {ProductPageSlotIds} from '../../../constants';
import {ProductPageTopSlot} from '../../ProductPageTopSlot/ProductPageTopSlot';
import classNames from 'classnames';
import {StunningLayoutHeader} from './StunningLayoutHeader';

export const StunningLayout: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const {product, slots} = globals;
  const {renderProductPageSlots} = globals.experiments ?? {};
  const isPluginInstalledInTopSlot = !!slots?.[ProductPageSlotIds.ProductPageTop];
  const isPluginInstalledInDetails9Slot = !!slots?.[ProductPageSlotIds.ProductPageDetails9];
  const isPluginInstalledInDetails8Slot = !!globals.slots?.[ProductPageSlotIds.ProductPageDetails8];
  const isPluginInstalledInBottomSlot = !!slots?.[ProductPageSlotIds.ProductPageBottom];

  return (
    <article className={s.container}>
      <div className={classNames({[s.topSlot]: isPluginInstalledInTopSlot})}>
        <ProductPageTopSlot />
      </div>
      {settings.shouldShowNavigation && (
        <Navigation
          className={classNames({
            [s.navigation]: !isPluginInstalledInTopSlot,
            [s.navigationSmallSpacingTop]: isPluginInstalledInTopSlot,
          })}
        />
      )}
      <StunningLayoutHeader globals={globals} settings={settings} />
      <section className={s.containerDescription}>
        <Cell className={s.description}>
          <ProductDescription description={product.description} />
        </Cell>
      </section>
      {renderProductPageSlots && (
        <Cell>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails4} />
        </Cell>
      )}
      {renderProductPageSlots && (
        <Cell className={classNames({[s.productPageSlotBigSpacing]: isPluginInstalledInDetails8Slot})}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails8} />
        </Cell>
      )}
      <section className={s.containerInfoSection}>
        {settings.shouldShowInfoSection && (
          <Cell className={s.info}>
            <InfoSectionLayout />
          </Cell>
        )}
      </section>
      {renderProductPageSlots && (
        <Cell className={classNames({[s.productPageSlotBottomSpacing]: isPluginInstalledInDetails9Slot})}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails9} />
        </Cell>
      )}
      {renderProductPageSlots && (
        <Cell className={classNames({[s.productPageSlot]: isPluginInstalledInBottomSlot})}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageBottom} />
        </Cell>
      )}
      <Cell>
        <ReviewsSlot />
        <BottomPlaceholder />
      </Cell>
    </article>
  );
};

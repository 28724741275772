/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface PlusTabsProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const PlusTabs: React.FC<PlusTabsProps> = ({size, ...props}) => (
  <svg viewBox="0 0 15 15" fill="currentColor" width={ size || "15" } height={ size || "15" } {...props}>
    <g id="Page-2" stroke="none" fill="none" strokeWidth="1" fillRule="evenodd">
      <g id="Desktop-1920-/-1080" fill="currentColor" fillRule="nonzero">
        <g id="new_plus" transform="translate(2 2)">
          <path id="Shape" d="M6 5L6 0 5 0 5 5 0 5 0 6 5 6 5 11 6 11 6 6 11 6 11 5z" />
        </g>
      </g>
    </g>
  </svg>
);
PlusTabs.displayName = 'PlusTabs';
/* tslint:enable */
/* eslint-enable */

interface Size {
  width: number;
  height: number;
}

export function getMediaDimensions(containerSize: Size, mediaSize: Size): Size {
  let ratio = 1;

  if (mediaSize.width > containerSize.width || mediaSize.height > containerSize.height) {
    ratio = Math.min(containerSize.width / mediaSize.width, containerSize.height / mediaSize.height);
  }

  return {
    width: Math.round(mediaSize.width * ratio),
    height: Math.round(mediaSize.height * ratio),
  };
}

import {ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import {InfoSectionLayoutId, TabsDirection} from '../../constants';
import {IWixStyleFont} from '@wix/wixstores-client-core/dist/es/src/types/wix-sdk';
import {IProductInfo} from '@wix/wixstores-graphql-schema';
import stylesParams from '../../../components/ProductPage/stylesParams';

export function geShowDividerFlagFromGlobalProps(props: ProvidedGlobalProps): boolean {
  return props.globals.styles.get(stylesParams.productPage_infoSectionShowDeviders);
}

export function geColumnNumberFromGlobalProps(props: ProvidedGlobalProps): number {
  return props.globals.styles.get(stylesParams.productPage_infoSectionColumnNumber);
}

export function getInfoSectionLayoutTypeFromGlobalProps(props: ProvidedGlobalProps): InfoSectionLayoutId {
  return props.globals.styles.get(stylesParams.productPage_infoSectionTypeId);
}

export function getAdditionalInfoFromGlobalProps(props: ProvidedGlobalProps): IProductInfo[] {
  return props.globals.product.additionalInfo;
}

export function getOnActiveBIEventHandler(props: ProvidedGlobalProps) {
  return props.globals.infoSection.onActive;
}

export function geTabFontFromGlobalProps(props: ProvidedGlobalProps): IWixStyleFont {
  return (
    props.globals.styles.get(stylesParams.productPage_infoSectionTitleFontStyle) ||
    props.globals.styles.get(stylesParams.quickView_infoSectionTitleFontStyle) ||
    props.globals.styles.get(stylesParams.productPage_paragraphTitleFontStyle)
  );
}

export function getIsRtlFromGlobalProps(props: ProvidedGlobalProps): boolean {
  return !!props.globals.isRTL;
}

export function getDirectionFromGlobalProps(props: ProvidedGlobalProps): TabsDirection {
  const infoSectionAlignment = props.globals.styles.get(stylesParams.productPage_infoSectionAlignment);
  if (!infoSectionAlignment || infoSectionAlignment.value === 'left' || infoSectionAlignment.value === 'center') {
    return TabsDirection.start;
  } else {
    return TabsDirection.end;
  }
}

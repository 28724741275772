import * as React from 'react';
import style from './NavigationArrowButton.scss';
import {ArrowsDir, keyboardEvents} from '../../../../../constants';
import classNames from 'classnames';
import {NavigationArrow} from './NavigationArrow/NavigationArrow';

export interface NavigationArrowButtonProps {
  direction: ArrowsDir;
  vertical: boolean;
  handleNavigation(direction?: ArrowsDir);
}

export enum DataHook {
  navigationArrowButton = 'navigation-arrow-button',
}

export const NavigationArrowButton: React.FunctionComponent<NavigationArrowButtonProps> = ({
  direction,
  handleNavigation,
  vertical,
}) => {
  const onKeypressNavigation = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    /* istanbul ignore else: todo: test */
    if (e.keyCode === keyboardEvents.ENTER.keyCode) {
      handleNavigation();
    }
  };

  const onNavigation = () => {
    /* istanbul ignore else: todo: test */
    if (direction === ArrowsDir.UP || direction === ArrowsDir.LEFT) {
      handleNavigation(ArrowsDir.LEFT);
    } else if (direction === ArrowsDir.DOWN || direction === ArrowsDir.RIGHT) {
      handleNavigation(ArrowsDir.RIGHT);
    }
  };

  const navigationButtonContainerClass = classNames({
    [style.verticalArrows]: vertical,
    [style.navigationArrows]: !vertical,
  });

  return (
    <div className={classNames([navigationButtonContainerClass, style[direction]])}>
      <button
        data-hook={`${DataHook.navigationArrowButton}-${direction}`}
        className={style.resetButton}
        type="button"
        onClick={onNavigation}
        onKeyPress={onKeypressNavigation}>
        <NavigationArrow direction={direction} />
      </button>
    </div>
  );
};

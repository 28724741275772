import * as React from 'react';

export function ModalArrowRight() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="28" viewBox="0 0 14 28">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" transform="translate(-1826 -526)">
          <g transform="translate(1808 516)">
            <path
              d="M37.9894251 17L24.9988982 28.9011986 12.1057782 17 11 17.9646436 24.9988982 31 39 17.9646436z"
              transform="rotate(-90 25 24)"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

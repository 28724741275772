import {SubscriptionPlanFrequency} from '@wix/wixstores-graphql-schema';

interface GetSubscriptionFrequencyTranslationKeyParams {
  frequency: SubscriptionPlanFrequency | keyof typeof SubscriptionPlanFrequency;
  interval: number;
}

export const getSubscriptionFrequencyTranslationKey = ({
  frequency,
  interval,
}: GetSubscriptionFrequencyTranslationKeyParams): string => {
  const keysFrequency = `${frequency.toLowerCase()}s`;
  switch (interval) {
    case 1:
      return `productPage.planPrice.${keysFrequency}.frequencySingular.label`;
    case 2:
      return `productPage.planPrice.${keysFrequency}.frequency2.label`;
    default:
      return `productPage.planPrice.${keysFrequency}.frequencyPlural.label`;
  }
};

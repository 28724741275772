import {isSantaFullWidthMode} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {GlobalProps} from '../../../providers/globalPropsProvider';
import {THUMBNAIL_ITEM_DIMENSION_PX} from './ProductMediaNavigation';
import {ArrowsDir} from '../../../constants';
import _ from 'lodash';
import {IMediaItem} from '../../../types/app-types';

export const getNavigationSpace = (
  isVertical: boolean,
  width: number,
  height: number,
  globals: GlobalProps
): number => {
  /* istanbul ignore if: todo: test */
  if (isSantaFullWidthMode(globals)) {
    return isVertical ? height : Math.min(980, width);
  } else {
    return isVertical ? height : width;
  }
};

export const isWithNavigationArrows = (
  isVertical: boolean,
  width: number,
  height: number,
  media: IMediaItem[],
  withDots: boolean,
  globals: GlobalProps
) => {
  if (withDots) {
    return false;
  } else {
    const space = getNavigationSpace(isVertical, width, height, globals);
    return space / THUMBNAIL_ITEM_DIMENSION_PX < media.length;
  }
};

export const getNumberOfSlidesToShow = (
  isVertical: boolean,
  width: number,
  height: number,
  media: IMediaItem[],
  globals: GlobalProps
): number => {
  const space = getNavigationSpace(isVertical, width, height, globals) - 50;
  return Math.min(Math.floor(space / THUMBNAIL_ITEM_DIMENSION_PX), media.length);
};

export const getNavigationData = (
  target: ArrowsDir | number,
  currentIndex: number,
  media: IMediaItem[]
): {slickPolicy: 'goTo' | 'next' | 'prev'; nextIndex: number} => {
  if (_.isNumber(target)) {
    return {nextIndex: target, slickPolicy: 'goTo'};
  }
  const op = target === (ArrowsDir.LEFT || /* istanbul ignore next: todo: test */ ArrowsDir.UP) ? _.subtract : _.add;
  const nextIndex = ((op(currentIndex, 1) % media.length) + media.length) % media.length;
  let slickPolicy;
  if (nextIndex === 0 || nextIndex === media.length - 1) {
    slickPolicy = 'goTo';
  } else if (nextIndex > currentIndex) {
    slickPolicy = 'next';
  } else {
    slickPolicy = 'prev';
  }
  return {nextIndex, slickPolicy};
};

import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import s from './PreOrderMessage.scss';

export const PreOrderMessage: React.FC<ProvidedGlobalProps> = withGlobalProps((props: ProvidedGlobalProps) => {
  const {
    selectedVariant,
    product: {inventory},
  } = props.globals;
  return (
    <div className={s.preOrderMessage} data-hook="pre-order-message">
      {inventory.preOrderInfoView.message || selectedVariant?.preOrderInfo?.message}
    </div>
  );
});

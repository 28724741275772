import * as React from 'react';
import {ArrowsDir} from '../../../../../../constants';
import {NavArrowDown, NavArrowLeft, NavArrowRight, NavArrowUp} from '../../../../../../icons/dist';

export interface NavigationArrowProps {
  direction: ArrowsDir;
}

export const NavigationArrow: React.FunctionComponent<NavigationArrowProps> = ({direction}) => {
  const size = {size: '20px'};
  switch (direction) {
    case ArrowsDir.UP:
      return <NavArrowUp {...size} />;
    case ArrowsDir.DOWN:
      return <NavArrowDown {...size} />;
    case ArrowsDir.LEFT:
      return <NavArrowLeft {...size} />;
    case ArrowsDir.RIGHT:
      return <NavArrowRight {...size} />;
  }
};

/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface PinterestProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const Pinterest: React.FC<PinterestProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path fill="currentColor" d="M7.162 10.438c-0.381 2.133-0.914 4.114-2.362 5.181-0.457-3.2 0.686-5.562 1.143-8.076-0.838-1.448 0.152-4.419 1.981-3.733 2.286 0.914-1.981 5.486 0.838 6.019 2.971 0.61 4.19-5.181 2.362-7.010-2.667-2.667-7.695-0.076-7.086 3.81 0.152 0.914 1.143 1.219 0.381 2.514-1.676-0.305-2.21-1.676-2.133-3.429 0.076-2.895 2.667-4.952 5.181-5.257 3.2-0.381 6.171 1.143 6.629 4.19 0.457 3.429-1.448 7.086-4.876 6.781-0.99-0.076-1.371-0.533-2.057-0.99z"
    />
  </svg>
);
Pinterest.displayName = 'Pinterest';
/* tslint:enable */
/* eslint-enable */

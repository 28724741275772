import React from 'react';
import classNames from 'classnames';
import {Cell} from '../Cell/Cell';
import {LayoutComponentProps} from '../../../types/app-types';
import {ProductPageSlotIds} from '../../../constants';
import s from './SimpleLayout.scss';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {ProductPrice} from '../../ProductPrice/ProductPrice';
import {ProductDiscountName} from '../../ProductDiscountName/ProductDiscountName';
import {ProductPaymentBreakdown} from '../../ProductPaymentBreakdown/ProductPaymentBreakdown';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductSocial} from '../../ProductSocial/ProductSocial';

export const SimpleLayoutRightSection: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const {product} = globals;
  const {renderProductPageSlots} = globals.experiments ?? {};
  const isPluginInstalledInDetails3Slot = !!globals.slots?.[ProductPageSlotIds.ProductPageDetails3];

  return (
    <section className={s.rightSection}>
      {settings.shouldShowPrice && (
        <Cell>
          <ProductPrice />
        </Cell>
      )}
      {renderProductPageSlots && (
        <Cell className={classNames({[s.productPageSlot]: isPluginInstalledInDetails3Slot})}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageDetails3} />
        </Cell>
      )}

      {settings.shouldShowDiscountName && product?.itemDiscount?.discountRuleName && (
        <Cell>
          <ProductDiscountName name={product.itemDiscount.discountRuleName} />
        </Cell>
      )}

      {settings.shouldShowProductPaymentBreakdown && (
        <Cell>
          <ProductPaymentBreakdown />
        </Cell>
      )}

      <Cell>
        <DetailsPlaceholder />
      </Cell>
      <Cell>
        <ProductOptions
          shouldShowQuantity={settings.shouldShowQuantity}
          shouldShowStockIndicator={settings.shouldShowStockIndicator}
        />
        <ProductPageButtonsContainer />
      </Cell>
      {settings.shouldShowSocialNetwork && (
        <Cell className={s.social}>
          <ProductSocial />
        </Cell>
      )}
    </section>
  );
};

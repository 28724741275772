import * as React from 'react';
import {withGlobalProps, ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import {ModalGalleryBaseProps} from './ModalGalleryBase';
import {ModalGalleryMobile} from './ModalGalleryMobile';
import {ModalGalleryDesktop} from './ModalGalleryDesktop';
import {MediaType} from '../../constants';
import {
  clickToOpenZoomModalSf,
  zoomModalIsClosedSf,
  viewZoomModalSf,
  pictureNavigationInZoomModalSf,
} from '@wix/bi-logger-ec-sf/v2';

export interface ZoomModalManager {
  isEnabled: boolean;
  open(): void;
}

interface ModalGalleryLayoutProps extends ProvidedGlobalProps {
  externalSelectedIndex: number;
  children(zoomModal: ZoomModalManager): JSX.Element;
}

interface ModalGalleryLayoutState {
  modalGalleryCurrentIndex: number;
  isModalGalleryOpen: boolean;
}

class ModalGalleryLayoutComponent extends React.Component<ModalGalleryLayoutProps, ModalGalleryLayoutState> {
  public state = {
    modalGalleryCurrentIndex: 0,
    isModalGalleryOpen: false,
  };

  private readonly open = () => {
    this.reportBI(clickToOpenZoomModalSf);
    this.setState({modalGalleryCurrentIndex: this.props.externalSelectedIndex, isModalGalleryOpen: true});
  };

  private readonly close = () => {
    this.reportBI(zoomModalIsClosedSf);
    this.setState({isModalGalleryOpen: false, modalGalleryCurrentIndex: 0});
  };

  private readonly onMount = () => {
    this.reportBI(viewZoomModalSf);
  };

  private readonly handleNavigateTo = (i: number) => {
    const value = this.state.modalGalleryCurrentIndex + 1 === i ? 'next' : 'previous';
    this.reportBI(pictureNavigationInZoomModalSf, {value});
    this.setState({modalGalleryCurrentIndex: i});
  };

  private readonly reportBI = (event, params = {}) => {
    const {reportBI, product} = this.props.globals;
    reportBI(
      event({
        productId: product.id,
        productType: product.productType,
        hasOptions: product.options.length > 0,
        ...params,
      })
    );
  };

  private renderModal() {
    const {
      globals: {product, isMobile},
    } = this.props;
    const {modalGalleryCurrentIndex, isModalGalleryOpen} = this.state;
    const C = isMobile ? ModalGalleryMobile : ModalGalleryDesktop;
    const props: ModalGalleryBaseProps = {
      media: product.media,
      currentIndex: modalGalleryCurrentIndex,
      handleNavigateTo: this.handleNavigateTo,
      handleClose: this.close,
      handleMount: this.onMount,
    };

    return <div data-hook="modal-gallery-on-dom">{isModalGalleryOpen && <C {...props} />}</div>;
  }

  public readonly noop = () => undefined;

  private get isEnabled() {
    return (
      this.props.globals?.withModalGallery &&
      this.props.globals.product.media[this.props.externalSelectedIndex]?.mediaType !== MediaType.VIDEO
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.isEnabled && this.renderModal()}
        {this.props.children({
          open: this.isEnabled ? this.open : this.noop,
          isEnabled: this.isEnabled,
        })}
      </>
    );
  }
}

export const ModalGalleryLayout = withGlobalProps(ModalGalleryLayoutComponent);

import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {PaymentMethodsBanner, PaymentMethodsBannerProps} from '@wix/payment-methods-banner/lazy';
import s from './ProductPaymentBreakdown.scss';

export enum ProductPaymentBreakdownDataHook {
  ProductPaymentBreakdown = 'product-payment-breakdown',
}

export const ProductPaymentBreakdown: React.FC<ProvidedGlobalProps> = withGlobalProps((props) => {
  const {breakdownParams, LazySentry, handleOpenModal} = props.globals;

  const openModal: PaymentMethodsBannerProps['openModal'] = (url, width, height) => {
    return handleOpenModal(url, {width, height});
  };

  return (
    <div data-hook={ProductPaymentBreakdownDataHook.ProductPaymentBreakdown} className={s.root}>
      <PaymentMethodsBanner {...breakdownParams} LazySentry={LazySentry} openModal={openModal} />
    </div>
  );
});

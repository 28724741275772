import * as React from 'react';

export function ModalArrowLeft() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="28" viewBox="0 0 15 28">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" transform="translate(-80 -526)">
          <g transform="translate(64 516)">
            <path
              d="M36.0464932 16.6605068L23.1919029 28.8898269 10.4337002 16.6605068 9.33949316 17.6517461 23.1919029 31.0464932 37.0464932 17.6517461z"
              transform="matrix(0 -1 -1 0 47.046 47.046)"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

import {GalleryNavigationLocation, GalleryNavigationPosition, GalleryNavigationType} from '../productPage/constants';
import {IProductPageSettings, ProductPageAppProps} from '../productPage/types/app-types';
import stylesParams from '../components/ProductPage/stylesParams';

export function getProductPageSettingsFromProps({
  globals: {styles, shouldShowProductPaymentBreakdown, shouldShowDiscountName},
}: ProductPageAppProps): IProductPageSettings {
  const navigationPositionString = styles.get(stylesParams.productPage_galleryNavigationPosition).value;
  const navigationTypeString = styles.get(stylesParams.productPage_galleryNavigationType).value;
  const navigationLocationString = styles.get(stylesParams.productPage_galleryNavigationLocation).value;
  const imageMode = styles.get(stylesParams.productPage_galleryImageMode);
  const imageRatioId = styles.get(stylesParams.productPage_galleryImageRatio);
  const infoSectionTypeId = styles.get(stylesParams.productPage_infoSectionTypeId);
  const infoSectionColumnNumber = styles.get(stylesParams.productPage_infoSectionColumnNumber);

  const shouldShowZoom = styles.get(stylesParams.productPage_galleryZoom);
  const shouldShowDivider = styles.get(stylesParams.productPage_infoSectionShowDeviders);
  const shouldShowPrice = styles.get(stylesParams.productPage_productPrice);
  const shouldShowSku = styles.get(stylesParams.productPage_productSku);
  const shouldShowQuantity = styles.get(stylesParams.productPage_productQuantity);
  const shouldShowStockIndicator = styles.get(stylesParams.productPage_stockIndicator);
  const shouldShowNavigation = styles.get(stylesParams.productPage_navigation);
  const shouldShowInfoSection = styles.get(stylesParams.productPage_productInfoSection);
  const shouldShowSocialNetwork = styles.get(stylesParams.productPage_socialNetworks);
  const isFullWidth = styles.get(stylesParams.full_width);

  const navigationPosition = navigationPositionString as GalleryNavigationPosition;
  const navigationType = navigationTypeString as GalleryNavigationType;
  const navigationLocation = navigationLocationString as GalleryNavigationLocation;

  return {
    navigationPosition,
    navigationType,
    navigationLocation,
    imageMode,
    imageRatioId,
    infoSectionTypeId,
    infoSectionColumnNumber,
    shouldShowDivider,
    shouldShowZoom,
    shouldShowPrice,
    shouldShowDiscountName,
    shouldShowSku,
    shouldShowQuantity,
    shouldShowStockIndicator,
    shouldShowNavigation,
    shouldShowInfoSection,
    shouldShowProductPaymentBreakdown,
    shouldShowSocialNetwork,
    isFullWidth,
  } as any;
}

import * as React from 'react';
import style from './Dot.scss';
import classNames from 'classnames';

export enum DataHook {
  dot = 'product-dot',
}

interface ForwardRefProps {
  forwardedRef: React.RefObject<HTMLButtonElement>;
  ref?: React.RefObject<HTMLButtonElement>;
}

export interface InnerDotProps {
  index: number;
  isSelected: boolean;

  handleClick(index: number): void;
  handleKeypress(e: React.KeyboardEvent<HTMLButtonElement>, index: number): void;
}

export type DotProps = InnerDotProps & ForwardRefProps;

class Dot extends React.Component<DotProps> {
  public render(): JSX.Element {
    const {forwardedRef, index, isSelected} = this.props;
    const dotClasses = classNames(style.dot, {[style.isActive]: isSelected});

    return (
      <li className={style.dotContainer} style={{width: 20}}>
        <button
          type="button"
          className={dotClasses}
          ref={forwardedRef}
          data-hook={DataHook.dot}
          onClick={() => this.props.handleClick(index)}
          onKeyPress={(e: React.KeyboardEvent<HTMLButtonElement>) => this.props.handleKeypress(e, index)}
          onKeyDownCapture={
            /* istanbul ignore next: todo: test */ (e: React.KeyboardEvent<HTMLButtonElement>) =>
              this.props.handleKeypress(e, index)
          }
        />
      </li>
    );
  }
}

function withRef(Component) {
  class CompWithRef extends React.Component<DotProps> {
    public render(): JSX.Element {
      const {forwardedRef, ...rest} = this.props;
      return <Component forwardedRef={forwardedRef} {...rest} />;
    }
  }

  return React.forwardRef((props: InnerDotProps, ref: React.RefObject<HTMLButtonElement>) => {
    return <CompWithRef {...props} forwardedRef={ref} />;
  });
}

export const DotWithRef = withRef(Dot);

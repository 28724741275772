/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface MinusTabsProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const MinusTabs: React.FC<MinusTabsProps> = ({size, ...props}) => (
  <svg viewBox="0 0 15 15" fill="currentColor" width={ size || "15" } height={ size || "15" } {...props}>
    <g id="Page-2" stroke="none" fill="none" strokeWidth="1" fillRule="evenodd">
      <g id="Desktop-1920-/-1080" fill="currentColor" fillRule="nonzero">
        <g id="new_minus" transform="translate(2 7)">
          <g id="Rectangle-5">
            <g id="Page-1">
              <g id="Desktop-1920-/-1080">
                <g id="Group">
                  <path id="Rectangle-5" d="M0 0L11 0 11 1 0 1z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
MinusTabs.displayName = 'MinusTabs';
/* tslint:enable */
/* eslint-enable */

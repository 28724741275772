/* istanbul ignore file: hard to test */
import {useEffect, useLayoutEffect, useState} from 'react';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export const useIsAfterHydration = () => {
  const [isAfterHydration, setAfterHydration] = useState(false);

  useIsomorphicLayoutEffect(() => {
    setAfterHydration(true);
  }, []);

  return {isAfterHydration};
};

import React from 'react';
import {ExpressCheckoutButtons} from '@wix/ecom-platform-express-checkout-buttons';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';

export const CashierExpressButton: React.FC<ProvidedGlobalProps> = withGlobalProps((props: ProvidedGlobalProps) => {
  const {currency, requestShipping, buttonStyle} = props.globals.cashierExpressCheckoutWidgetProps;
  const {isAllowedToCheckout, dynamicPaymentMethodsTheme, cashierExpressEstimatedTotal} = props.globals;

  return (
    <ExpressCheckoutButtons
      key={currency}
      layout={'vertical'}
      theme={dynamicPaymentMethodsTheme}
      buttonStyle={buttonStyle}
      isShippable={requestShipping}
      estimatedTotal={cashierExpressEstimatedTotal}
      canStartPayment={isAllowedToCheckout}
    />
  );
});

import React from 'react';
import {Breadcrumbs as HistoricalBreadcrumbs} from './Breadcrumbs/Breadcrumbs';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {Pagination, PaginationProps} from './Pagination/Pagination';
import cx from 'classnames';
import s from './Navigation.scss';
import {ProductPagePaginationItem} from '../../types/app-types';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ShortBreadcrumbs as BackBreadcrumbs} from './ShortBreadcrumbs/ShortBreadcrumbs';
import {Breadcrumbs} from 'wix-ui-tpa/cssVars';
import {CATEGORY_PAGE_URL_PREFIX} from '../../constants';

export interface NavigationProps {
  className?: string;
  type?: NavigationType;
  renderBreadcrumbs: boolean;
  renderPagination: boolean;
}

export const enum NavigationType {
  Short,
  Long,
  PaginationOnly,
}

export const enum NavigationDataHooks {
  ShortBreadcrumbs = 'short-breadcrumbs',
  Root = 'navigation',
  TaxonomicalBreadcrumbs = 'breadcrumbs',
}
class NavigationComponent extends React.Component<NavigationProps & ProvidedGlobalProps & IProvidedTranslationProps> {
  public static defaultProps = {
    type: NavigationType.Long,
    renderBreadcrumbs: true,
    renderPagination: true,
  };

  private navigationProps(): PaginationProps {
    /* istanbul ignore next: todo: test */ const {
      t,
      globals: {
        navigate,
        pagination: {prevProduct = {} as ProductPagePaginationItem, nextProduct = {} as ProductPagePaginationItem},
        isRTL,
        experiments: {breadcrumbsAfterHydration},
      },
    } = this.props;

    return {
      isRTL,
      prev: {
        text: t('BREADCRUMBS_PAGINATION_PREVIOUS'),
        href: prevProduct.fullUrl,
        onClick: (e) => {
          e.preventDefault();
          navigate(prevProduct.partialUrl);
        },
      },
      next: {
        text: t('BREADCRUMBS_PAGINATION_NEXT'),
        href: nextProduct.fullUrl,
        onClick: (e) => {
          e.preventDefault();
          navigate(nextProduct.partialUrl);
        },
      },
      shouldRenderAfterHydration: breadcrumbsAfterHydration,
    };
  }

  private renderBack() {
    const {
      globals: {
        siteUrl,
        pagePath,
        hideNavigationUrls,
        isRTL,
        experiments: {breadcrumbsAfterHydration},
      },
      t,
    } = this.props;

    const previousPage = pagePath[pagePath.length - 2];
    /* istanbul ignore next */ const href = previousPage ? previousPage.url : siteUrl;
    /* istanbul ignore next */ const pageName = previousPage ? previousPage.name : t('BREADCRUMBS_HOME');
    return (
      <BackBreadcrumbs
        pageName={pageName}
        href={href}
        isRTL={isRTL}
        hideNavigationUrls={hideNavigationUrls}
        t={t}
        shouldRenderAfterHydration={breadcrumbsAfterHydration}
      />
    );
  }

  private renderTaxonimicalBreadcrumbs() {
    const {t} = this.props;
    const {product, siteUrl} = this.props.globals;

    /* istanbul ignore next */
    if (!product) {
      return [];
    }

    const categoryBaseUrl = `${siteUrl}/${CATEGORY_PAGE_URL_PREFIX}`;

    const breadcrumbItems = product.breadcrumbs?.map((breadcrumb) => {
      return {id: breadcrumb.id, value: breadcrumb.name, href: `${categoryBaseUrl}/${breadcrumb.slug}`};
    });

    const breadcrumbs = [
      {
        id: 'home',
        value: t('BREADCRUMBS_HOME'),
        href: siteUrl,
      },
      ...(breadcrumbItems || /* istanbul ignore next */ []),
      {
        id: product.id,
        value: product.name,
        href: '',
      },
    ];

    return (
      <Breadcrumbs
        items={breadcrumbs}
        data-hook={NavigationDataHooks.TaxonomicalBreadcrumbs}
        showTrailOnMobileMode={true}
      />
    );
  }

  private renderHistoricalBreadcrumbs() {
    const {
      pagePath,
      navigateToLink,
      hideNavigationUrls,
      experiments: {breadcrumbsAfterHydration},
    } = this.props.globals;

    return (
      <HistoricalBreadcrumbs
        useHref={!hideNavigationUrls}
        path={pagePath}
        navigateToLink={navigateToLink}
        shouldRenderAfterHydration={breadcrumbsAfterHydration}
      />
    );
  }

  private renderPagination() {
    const {next, prev, isRTL, shouldRenderAfterHydration} = this.navigationProps();
    return <Pagination next={next} prev={prev} isRTL={isRTL} shouldRenderAfterHydration={shouldRenderAfterHydration} />;
  }

  public render(): JSX.Element {
    const {
      className,
      type,
      renderBreadcrumbs,
      renderPagination,
      globals: {
        product,
        experiments: {shouldRenderTaxonomicalBreadCrumbsInPP},
      },
    } = this.props;

    const productHasBreadcrumbs = product?.breadcrumbs && product?.breadcrumbs.length !== 0;
    const renderTaxonomicalBreadCrumbsInPP = productHasBreadcrumbs && shouldRenderTaxonomicalBreadCrumbsInPP;

    return (
      <nav data-hook={NavigationDataHooks.Root} className={cx(className, s.navigation)}>
        <div className={s.breadcrumbs}>
          {renderBreadcrumbs && renderTaxonomicalBreadCrumbsInPP && this.renderTaxonimicalBreadcrumbs()}
          {renderBreadcrumbs &&
            !renderTaxonomicalBreadCrumbsInPP &&
            (type === NavigationType.Short ? this.renderBack() : this.renderHistoricalBreadcrumbs())}
        </div>
        {renderPagination && type === NavigationType.Long && this.renderPagination()}
      </nav>
    );
  }
}

export const Navigation = withGlobalProps(withTranslations('globals.texts')(NavigationComponent));

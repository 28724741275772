import React from 'react';
import s from './Pagination.scss';
import {ArrowLeft, ArrowRight} from '../../../icons/dist';
import classNames from 'classnames';
import {useIsAfterHydration} from '../hooks/useIsAfterHydration';

export interface PaginationProps {
  prev: PaginationItem;
  next: PaginationItem;
  isRTL: boolean;
  shouldRenderAfterHydration: boolean;
}

export interface PaginationItem {
  text: string;
  href?: string;
  onClick?(event): void;
}

export const enum DataHooks {
  Pagination = 'pagination',
  HeightPlaceholder = 'pagination.height-placeholder',
}

export const Pagination = ({prev, next, isRTL, shouldRenderAfterHydration}: PaginationProps) => {
  const {isAfterHydration} = useIsAfterHydration();
  const shouldRenderPagination =
    ((shouldRenderAfterHydration ? /* istanbul ignore next: hard to test */ isAfterHydration : true) && next.href) ||
    prev.href;

  const renderLink = (item: PaginationItem, rel: 'prev' | 'next') => {
    const {href, onClick} = item;

    /* eslint-disable react/jsx-no-useless-fragment */
    const content = (
      <>
        {rel === 'prev' ? (
          <>
            <ArrowLeft />
            {item.text}
          </>
        ) : (
          <>
            {item.text}
            <ArrowRight />
          </>
        )}
      </>
    );

    return (
      <a rel={rel} href={href} className={classNames(s[rel], {[s.rtl]: isRTL})} onClick={onClick}>
        {content}
      </a>
    );
  };

  const FullState = () => (
    <>
      {renderLink(prev, 'prev')}
      {renderLink(next, 'next')}
    </>
  );

  const HeightPlaceholder = () => (
    <div className={s.hidden} data-hook={DataHooks.HeightPlaceholder}>
      <ArrowLeft />
    </div>
  );

  return (
    <div data-hook={DataHooks.Pagination} className={s.pagination}>
      {shouldRenderPagination ? <FullState /> : <HeightPlaceholder />}
    </div>
  );
};

import React from 'react';
import s from './ColumnsInfoItem.scss';
import classNames from 'classnames';
import {InfoSectionDescription} from '../../../InfoSectionDescription/InfoSectionDescription';
import {InfoSectionTitle} from '../../../InfoSectionTitle/InfoSectionTitle';
import {InfoSectionLayoutId} from '../../../../../constants';

export enum DataHook {
  ColumnsInfoItem = 'columns-info-item',
}

export interface InfoSectionColumnsProps {
  description: string;
  index: number;
  title: string;
  shouldShowDivider: boolean;
  numberOfColumns: number;
}

export class ColumnsInfoItem extends React.Component<InfoSectionColumnsProps> {
  public render(): JSX.Element {
    const {description, title, shouldShowDivider, numberOfColumns, index} = this.props;
    const infoSectionColumnsClass = classNames(
      s.container,
      {[s.whenOneColumns]: numberOfColumns === 1},
      {[s.whenTwoColumns]: numberOfColumns === 2},
      {[s.whenThreeColumns]: numberOfColumns === 3},
      {[s.withDivider]: shouldShowDivider && numberOfColumns > 1}
    );

    return (
      <li className={infoSectionColumnsClass} data-hook={DataHook.ColumnsInfoItem}>
        <InfoSectionTitle theme={InfoSectionLayoutId.Columns} title={title} />
        <InfoSectionDescription description={description} index={index} />
      </li>
    );
  }
}

/* eslint-disable */
import * as React from 'react';
export interface WhatsappProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Whatsapp: React.SFC<WhatsappProps> = ({size, ...props}) => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px"
       viewBox="0 0 16 16" width={ size || "16" } height={ size || "16" } {...props}>
  <g>
    <path fill="#E0E0E0" d="M13.6,2.3C12.1,0.8,10.2,0,8,0C3.7,0,0.1,3.6,0.1,7.9c0,1.4,0.4,2.8,1.1,4L0,16l4.2-1.1c1.2,0.6,2.5,1,3.8,1h0
      h0c4.4,0,7.9-3.6,7.9-7.9C16,5.8,15.1,3.8,13.6,2.3L13.6,2.3z M8,14.5L8,14.5c-1.2,0-2.3-0.3-3.4-0.9l-0.2-0.1l-2.5,0.7l0.7-2.4
      l-0.2-0.2c-0.7-1-1-2.3-1-3.5c0-3.6,3-6.6,6.6-6.6c1.8,0,3.4,0.7,4.7,1.9c1.2,1.2,1.9,2.9,1.9,4.7C14.6,11.6,11.7,14.5,8,14.5z"/>

    <path fill="#60D66A" d="M0.4,15.5l1.1-3.9c-0.7-1.1-1-2.4-1-3.8c0-4.2,3.4-7.6,7.6-7.6c2,0,3.9,0.8,5.4,2.2c1.4,1.4,2.2,3.3,2.2,5.4
      c0,4.2-3.4,7.6-7.6,7.6c0,0,0,0,0,0h0c-1.3,0-2.5-0.3-3.6-0.9L0.4,15.5L0.4,15.5z"/>
    <path fill="#FFFFFF" d="M6.1,4.6C5.9,4.2,5.8,4.2,5.6,4.2c-0.1,0-0.2,0-0.4,0c-0.1,0-0.3,0-0.5,0.2C4.6,4.7,4,5.1,4,6.1
      c0,1,0.7,1.9,0.8,2c0.1,0.1,1.4,2.2,3.3,3c1.7,0.7,2,0.5,2.4,0.5c0.4,0,1.2-0.5,1.3-0.9C12,10.2,12,9.8,12,9.7
      c0-0.1-0.2-0.1-0.4-0.2c-0.2-0.1-1.2-0.6-1.3-0.6C10.1,8.8,10,8.7,9.8,8.9C9.7,9.1,9.3,9.6,9.2,9.7C9.1,9.8,9,9.9,8.8,9.8
      C8.6,9.7,8,9.5,7.2,8.8c-0.6-0.5-1-1.2-1.1-1.4C6,7.2,6.1,7.1,6.2,7c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3
      c0.1-0.1,0-0.2,0-0.3C6.6,5.9,6.2,4.9,6.1,4.6L6.1,4.6z"/>
    <path fill="#FFFFFF" d="M13.6,2.3C12.1,0.8,10.1,0,8,0C3.7,0,0.2,3.5,0.2,7.8c0,1.4,0.4,2.7,1,3.9l-1.1,4.1l4.2-1.1
      c1.1,0.6,2.4,1,3.7,1h0h0c4.3,0,7.8-3.5,7.8-7.8C15.9,5.8,15.1,3.8,13.6,2.3L13.6,2.3z M8,14.4L8,14.4c-1.2,0-2.3-0.3-3.3-0.9
      l-0.2-0.1L2,14l0.7-2.4l-0.2-0.2c-0.7-1-1-2.2-1-3.5c0-3.6,2.9-6.5,6.5-6.5c1.7,0,3.4,0.7,4.6,1.9c1.2,1.2,1.9,2.9,1.9,4.6
      C14.5,11.4,11.6,14.4,8,14.4z"/>
  </g>
</svg>

);
Whatsapp.displayName = 'Whatsapp';
/* eslint-enable */

/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface PlayProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const Play: React.FC<PlayProps> = ({size, ...props}) => (
  <svg viewBox="0 0 14 16" fill="currentColor" width={ size || "1em" } height={ size || "1em" } {...props}>
    <path d="M0 0 V16 L14 8Z" />
  </svg>
);
Play.displayName = 'Play';
/* tslint:enable */
/* eslint-enable */

/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface ArrowLeftProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const ArrowLeft: React.FC<ArrowLeftProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path fill="currentColor" d="M11.519 13.934c0.214 0.217 0.214 0.566 0 0.783s-0.561 0.217-0.775 0l-6.264-6.326c-0.214-0.217-0.214-0.567 0-0.783l6.264-6.326c0.214-0.217 0.561-0.217 0.775 0s0.214 0.566 0 0.783l-5.712 5.934 5.712 5.934z"
    />
  </svg>
);
ArrowLeft.displayName = 'ArrowLeft';
/* tslint:enable */
/* eslint-enable */

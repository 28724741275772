import React from 'react';
import _ from 'lodash';
import SlickSlider, {Settings as SlickSettings} from 'react-slick';
import classNames from 'classnames';
import style from './ProductMediaNavigation.scss';
import {
  convertConfigToNumber,
  getMediaUrl,
  isSantaFullWidthMode,
} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {Alignment, ArrowsDir} from '../../../constants';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {ThumbnailsNavigation} from './ThumbnailsNavigation/ThumbnailsNavigation';
import {MediaNavigationItem} from './MediaNavigationItem/MediaNavigationItem';
import {DimensionsConfig, IMediaItem, IRefButtonWithIndex} from '../../../types/app-types';
import {ProductGalleryContext} from '../ProductGalleryProvider/ProductGalleryProvider';
import {
  getNavigationData,
  getNavigationSpace,
  getNumberOfSlidesToShow,
  isWithNavigationArrows,
} from './ProductMediaNavigationUtils';

export interface ProductMediaNavigationProps extends ProvidedGlobalProps {
  media: IMediaItem[];
  vertical: boolean;
  align: Alignment;
  layoutDimensions: DimensionsConfig;
  withDots: boolean;
}

export interface ProductMediaNavigationState {
  width: number;
  height: number;
  mountedDOM: boolean;
  currentActiveThumbnailIndex: number;
}

export const THUMBNAIL_ITEM_DIMENSION_PX: number = 65;
export const THUMBNAIL_IMAGE_DIMENSION_PX: number = 45;

const ThumbnailsPropsDefaultProps: Partial<ProductMediaNavigationProps> = {
  align: Alignment.CENTER,
};

class ProductMediaNavigationComponent extends React.Component<
  ProductMediaNavigationProps,
  ProductMediaNavigationState
> {
  private thumbnailsRefArray: IRefButtonWithIndex[] = [];
  private _ref: HTMLDivElement;
  private _slickRef: SlickSlider;

  constructor(props: ProductMediaNavigationProps) {
    super(props);
    this.state = this.getInitialState(props);
    this.setThumbnailsRefArray(props);
  }

  private readonly getDimensions = () => {
    return {height: this._ref.parentElement.clientHeight, width: this._ref.parentElement.clientWidth};
  };

  public componentDidMount(): void {
    this.setState({
      ...this.getDimensions(),
      mountedDOM: true,
    });
  }

  public UNSAFE_componentWillReceiveProps(props: ProductMediaNavigationProps): void {
    this.setThumbnailsRefArray(props);
    this.setState({...this.getDimensions()});
  }

  private readonly getRefFromThumbnailsRefArray = (index: number) => {
    return this.thumbnailsRefArray.find((refWithIndexObj) => refWithIndexObj.index === index).ref;
  };

  private readonly setThumbnailsRefArray = (props) => {
    this.thumbnailsRefArray = props.media.reduce((acc, _item, index) => {
      acc.push({index, ref: React.createRef<HTMLButtonElement>()});
      return acc;
    }, []);
  };

  private readonly getInitialState = (props: ProductMediaNavigationProps): ProductMediaNavigationState => {
    const {
      globals: {
        dimensions: {height, width},
      },
      layoutDimensions: {widthConf, heightConf},
    } = props;
    return {
      width: /* istanbul ignore next: todo: test */ widthConf ? convertConfigToNumber(widthConf, width) : 0,
      height: /* istanbul ignore next: todo: test */ heightConf ? convertConfigToNumber(heightConf, height) : 0,
      mountedDOM: false,
      currentActiveThumbnailIndex: 0,
    };
  };

  private getSlickSettings(): SlickSettings {
    const {vertical, withDots, media, globals} = this.props;
    const {width, height} = this.state;
    const slidesToShow = getNumberOfSlidesToShow(vertical, width, height, media, globals);
    return {
      arrows: false,
      dots: false,
      infinite: false,
      slidesToShow: withDots ? 15 : slidesToShow + 1,
      slidesToScroll: withDots ? 15 : 1,
      speed: 400,
      accessibility: false,
      swipe: false,
      focusOnSelect: true,
      vertical: withDots ? false : vertical,
      variableWidth: withDots,
    };
  }

  private readonly getTargetThumbnailIndex = (targetIndex) => {
    const {media} = this.props;
    let nextIndex = targetIndex;
    if (targetIndex < 0) {
      nextIndex = media.length - 1;
    } else if (targetIndex === media.length) {
      nextIndex = 0;
    }
    return nextIndex;
  };

  private readonly focusOnTargetThumbnail = (targetIndex: number) => {
    const currentActiveThumbnailIndex = this.getTargetThumbnailIndex(targetIndex);
    const currentThumbnail = this.getRefFromThumbnailsRefArray(currentActiveThumbnailIndex);
    currentThumbnail.current.focus();
    this.setState({currentActiveThumbnailIndex});
  };

  private readonly smartSlickGoTo = (target: number) => {
    const {media, vertical, globals} = this.props;
    const {width, height} = this.state;
    const slidesToShow = getNumberOfSlidesToShow(vertical, width, height, media, globals);
    const lastIndexToNavigate = media.length - slidesToShow;
    /* istanbul ignore next: todo: test */ const next = _.inRange(target, lastIndexToNavigate, media.length)
      ? lastIndexToNavigate
      : target;
    this._slickRef.slickGoTo(next);
    this.focusOnTargetThumbnail(target);
  };

  public readonly getThumbnailOrDotItem = (
    mediaItem: IMediaItem,
    index: number,
    isSelected: boolean,
    onThumbnailClick: (index: number) => void
  ): JSX.Element => {
    const {vertical, withDots} = this.props;
    const dimensions = {
      width: THUMBNAIL_IMAGE_DIMENSION_PX,
      height: THUMBNAIL_IMAGE_DIMENSION_PX,
    };
    const imgUrl = getMediaUrl(mediaItem, dimensions, {isSSR: !this.state.mountedDOM});
    return (
      <MediaNavigationItem
        key={index}
        index={index}
        handleClick={onThumbnailClick}
        withDots={withDots}
        isSelected={isSelected}
        isVertical={vertical}
        imgUrl={imgUrl}
        mediaItem={mediaItem}
        ref={this.getRefFromThumbnailsRefArray(index) as any}
      />
    );
  };

  public getSlickSlider = (selectedIndex: number, withNavigationArrows: boolean, onNavigate) => {
    const {media, vertical} = this.props;
    const slideClasses = classNames(
      {[style.carouselWithVerticalNavigationArrows]: withNavigationArrows && vertical},
      {[style.carouselWithNavigationArrows]: withNavigationArrows}
    );
    return (
      <SlickSlider
        data-hook="thumbnails-slick"
        {...this.getSlickSettings()}
        className={slideClasses}
        ref={(slider) => (this._slickRef = slider)}>
        {_.map(media, (item, index) => {
          return this.getThumbnailOrDotItem(item, index, index === selectedIndex, onNavigate);
        })}
      </SlickSlider>
    );
  };

  public readonly getThumbnails = (selectedIndex: number, changeSelectedIndex: Function) => {
    const {vertical, media, withDots, globals} = this.props;
    const {width, height} = this.state;
    const withNavigationArrows = isWithNavigationArrows(vertical, width, height, media, withDots, globals);

    const onNavigate = (target: ArrowsDir | number) => {
      const newTarget = this.getTargetThumbnailIndex(target);
      const {nextIndex, slickPolicy} = getNavigationData(newTarget, selectedIndex, media);
      /* istanbul ignore else  */
      if (slickPolicy === 'goTo') {
        this.smartSlickGoTo(nextIndex);
      } else if (slickPolicy === 'next') {
        this._slickRef.slickNext();
        this.focusOnTargetThumbnail(this.state.currentActiveThumbnailIndex + 1);
      } else if (slickPolicy === 'prev') {
        this._slickRef.slickPrev();
        this.focusOnTargetThumbnail(this.state.currentActiveThumbnailIndex - 1);
      }
      changeSelectedIndex(nextIndex);
    };
    return withNavigationArrows ? (
      <ThumbnailsNavigation onNavigation={onNavigate} vertical={vertical}>
        {this.getSlickSlider(selectedIndex, withNavigationArrows, onNavigate)}
      </ThumbnailsNavigation>
    ) : (
      this.getSlickSlider(selectedIndex, withNavigationArrows, onNavigate)
    );
  };

  public render(): JSX.Element {
    const {media, vertical, align, withDots, globals} = this.props;
    const {width, height} = this.state;
    const withNavigationArrows = isWithNavigationArrows(vertical, width, height, media, withDots, globals);
    const thumbnailsClasses = classNames(
      style.root,
      {[style.vertical]: vertical},
      {[style.alignCenter]: align === Alignment.CENTER},
      {[style.withVerticalNavigationArrows]: withNavigationArrows && vertical},
      {[style.withNavigationArrows]: withNavigationArrows},
      {[style.withDots]: withDots}
    );
    const extraWidth = media.length === 2 ? 15 : 0;
    const dynamicDimension = vertical
      ? {
          height: `${Math.min(media.length * THUMBNAIL_ITEM_DIMENSION_PX, height)}px`,
        }
      : {
          width: `${Math.min(
            media.length * THUMBNAIL_ITEM_DIMENSION_PX + extraWidth,
            isSantaFullWidthMode(this.props.globals)
              ? /* istanbul ignore next: ui test*/ getNavigationSpace(vertical, width, height, globals)
              : width
          )}px`,
        };

    return (
      <div data-hook="thumbnails" ref={(r) => (this._ref = r)} className={thumbnailsClasses} style={dynamicDimension}>
        <ProductGalleryContext.Consumer>
          {({selectedIndex, changeSelectedIndex}) => {
            return this.getThumbnails(selectedIndex, changeSelectedIndex);
          }}
        </ProductGalleryContext.Consumer>
      </div>
    );
  }

  public static defaultProps = ThumbnailsPropsDefaultProps;
}

export const ProductMediaNavigation = withGlobalProps(ProductMediaNavigationComponent);

import * as React from 'react';
import {ModalGalleryBase, Hooks, Zoom, ZoomDPR, ZoomDirection} from './ModalGalleryBase';
import cx from 'classnames';
import s from './ModalGallery.scss';
import {ModalArrowLeft} from './assets/ModalArrowLeft';
import {ModalArrowRight} from './assets/ModalArrowRight';

/* istanbul ignore next: this is actually covered but there is a bug with istanbul  https://github.com/gotwarlost/istanbul/issues/690 */
export class ModalGalleryDesktop extends ModalGalleryBase {
  protected direction: ZoomDirection = ZoomDirection.DESKTOP;
  protected dpr: ZoomDPR = ZoomDPR.DESKTOP;

  protected bindEvents() {
    window.addEventListener('keydown', this.handleKeydown, {passive: false});
  }

  protected unbindEvents() {
    window.removeEventListener('keydown', this.handleKeydown);
  }

  protected get margins() {
    return {y: 160, x: 260};
  }

  protected onMount = () => {
    if (this.props.media.length === 1) {
      /* istanbul ignore next */
      const x = (window.event as MouseEvent)?.clientX;
      /* istanbul ignore next */
      const y = (window.event as MouseEvent)?.clientY;
      this.setZoom(Zoom.ON, {x, y});
    }
  };

  protected get gestures() {
    return {
      onMouseMove: this.handleMouseMove,
      onClick: this.handleMouseClick,
    };
  }

  private readonly handleKeydown = (event: KeyboardEvent) => {
    const isArrowRight = event.key === 'ArrowRight';
    const isArrowLeft = event.key === 'ArrowLeft';
    const isEscape = event.key === 'Escape';

    if (isArrowRight) {
      return this.navigateNext();
    }
    if (isArrowLeft) {
      return this.navigatePrev();
    }
    if (isEscape) {
      return this.props.handleClose();
    }
  };

  private readonly handleMouseClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setZoom(this.isZoomed ? Zoom.OFF : Zoom.ON, {
      x: this.isZoomed ? 0 : event.clientX,
      y: this.isZoomed ? 0 : event.clientY,
    });
  };

  private readonly handleMouseMove = (event: React.MouseEvent) => {
    if (this.isZoomed) {
      this.setAxis({x: event.clientX, y: event.clientY});
    }
  };

  protected renderArrows() {
    const {currentIndex, media} = this.props;
    const withPrev = currentIndex > 0;
    const withNext = currentIndex < media.length - 1;

    return (
      <>
        {withPrev && (
          <a data-hook={Hooks.ArrowPrev} className={cx(s.arrow, s.prev)} onClick={() => this.navigatePrev()}>
            <ModalArrowLeft />
          </a>
        )}
        {withNext && (
          <a data-hook={Hooks.ArrowNext} className={cx(s.arrow, s.next)} onClick={() => this.navigateNext()}>
            <ModalArrowRight />
          </a>
        )}
      </>
    );
  }
}

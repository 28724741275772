import React, {Component} from 'react';
import {RadioButton, RadioButtonTheme} from 'wix-ui-tpa';
import {RadioButton as RadioButtonCore} from 'wix-ui-core/radio-button';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../providers/globalPropsProvider';
import s from './SubscriptionPlan.scss';
import {SubscriptionPlanFrequency} from '../../../../../graphql/queries-schema';
import classNames from 'classnames';
import {classes} from './SubscriptionPlan.st.css';
import {getSubscriptionDetailsTranslation} from './translationUtils';

export const enum SubscriptionPlanDataHooks {
  PLAN_ITEM = 'plan-item',
  NAME = 'plan-name',
  TAGLINE = 'plan-tagline',
  PRICE = 'plan-price',
  SELECTION = 'plan-selection',
  SELECTION_ICON = 'SELECTION_ICON',
  DETAILS = 'plan-details',
}

export interface ISubscriptionPlan {
  id?: string;
  name: string;
  isOneTimePurchase: boolean;
  formattedPrice: string;
  price?: number;
  tagline?: string;
  frequency?: SubscriptionPlanFrequency;
  duration?: number;
  interval?: number;
}

export interface ISubscriptionPlanProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  plan: ISubscriptionPlan;
  planVariantFormattedPrice?: string;
  isSelected: boolean;
  accessibilityEnabled?: boolean;
  className?: string;
  onClick?(): void;
}

class SubscriptionPlanComponent extends Component<ISubscriptionPlanProps> {
  private readonly onClick = () => {
    const {onClick: onCompClicked} = this.props;
    onCompClicked && onCompClicked();
  };

  private readonly getRadioButtonIcon = (checked: boolean) => {
    const circleClass = classNames(s.circle, {[s.checkedCircle]: checked});
    return <span data-hook={SubscriptionPlanDataHooks.SELECTION_ICON} className={circleClass} />;
  };

  private renderSubscriptionContent() {
    const {planVariantFormattedPrice, t} = this.props;
    const {name, tagline, formattedPrice, frequency, interval} = this.props.plan;

    return (
      <div>
        <div data-hook={SubscriptionPlanDataHooks.NAME} className={s.name}>
          {name}
        </div>
        {!!tagline && tagline.trim() && (
          <div className={s.subtitle} data-hook={SubscriptionPlanDataHooks.TAGLINE}>
            {tagline}
          </div>
        )}
        <div>
          <span data-hook={SubscriptionPlanDataHooks.PRICE} className={s.price}>
            {planVariantFormattedPrice || formattedPrice}
          </span>
          {!!frequency && !!interval && (
            <span style={{fontSize: '12px', marginLeft: '4px'}} data-hook={SubscriptionPlanDataHooks.DETAILS}>
              {getSubscriptionDetailsTranslation({t, plan: this.props.plan})}
            </span>
          )}
        </div>
      </div>
    );
  }

  public render(): JSX.Element {
    const {
      isSelected,
      className,
      globals: {product, experiments},
    } = this.props;
    const {name} = this.props.plan;
    const subscriptionPlanClasses = classNames(s.container);

    if (experiments.isSubscriptionPlanTpaRadioButton) {
      return (
        <div className={className}>
          <RadioButton
            value={name}
            onChange={this.onClick}
            data-hook={SubscriptionPlanDataHooks.SELECTION}
            checked={isSelected}
            aria-label={name}
            theme={RadioButtonTheme.Box}
            name={product.id}
            className={classes.subscriptionPlanRadioButton}>
            <div
              data-hook={SubscriptionPlanDataHooks.PLAN_ITEM}
              className={subscriptionPlanClasses}
              onClick={this.onClick}>
              {this.renderSubscriptionContent()}
            </div>
          </RadioButton>
        </div>
      );
    } else {
      const subscriptionPlanClassesOld = classNames(s['old-container'], className, {[s.selected]: isSelected});
      return (
        <div
          data-hook={SubscriptionPlanDataHooks.PLAN_ITEM}
          className={subscriptionPlanClassesOld}
          onClick={this.onClick}>
          <span className={s.radioButton}>
            <RadioButtonCore
              data-hook={SubscriptionPlanDataHooks.SELECTION}
              checked={isSelected}
              checkedIcon={this.getRadioButtonIcon(true)}
              uncheckedIcon={this.getRadioButtonIcon(false)}
              aria-label={name}
              name={product.id}
            />
          </span>
          {this.renderSubscriptionContent()}
        </div>
      );
    }
  }
}

export const SubscriptionPlan: React.FC<ISubscriptionPlanProps> = withGlobalProps(
  withTranslations('globals.texts')(SubscriptionPlanComponent)
);

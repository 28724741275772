/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface NavArrowUpProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const NavArrowUp: React.FC<NavArrowUpProps> = ({size, ...props}) => (
  <svg viewBox="0 0 17 16" fill="currentColor" width={ size || "1em" } height={ size || "1em" } {...props}>
    <path d="M15.812,9.896 C15.587,9.896 15.361,9.834 15.162,9.699 L8.932,5.543 L2.895,9.74 C2.354,10.099 1.625,9.953 1.266,9.412 C0.905,8.873 1.051,8.142 1.592,7.783 L8.28,3.152 C8.673,2.888 9.188,2.888 9.583,3.15 L16.464,7.74 C17.005,8.099 17.152,8.832 16.792,9.371 C16.564,9.713 16.191,9.896 15.812,9.896 L15.812,9.896 Z"
      fill="currentColor" fillRule="evenodd" />
  </svg>
);
NavArrowUp.displayName = 'NavArrowUp';
/* tslint:enable */
/* eslint-enable */

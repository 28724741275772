import React from 'react';
import s from './InfoSectionTitle.scss';
import {InfoSectionLayoutId} from '../../../constants';
import classNames from 'classnames';
import {Text} from 'wix-ui-tpa';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {classes as infoSectionTitleStylable} from './InfoSectionTitle.st.css';

export enum DataHook {
  Title = 'info-section-title',
}

export interface InfoSectionTitleProps {
  title: string;
  theme: InfoSectionLayoutId;
}

export const InfoSectionTitle: React.FC<InfoSectionTitleProps & ProvidedGlobalProps> = withGlobalProps(
  (props: InfoSectionTitleProps & ProvidedGlobalProps) => {
    const {
      theme,
      title,
      globals: {htmlTags},
    } = props;

    const titleClassName = classNames(
      s.general,
      {[s.collapse]: theme === InfoSectionLayoutId.Collapse},
      {[s.stacked]: theme === InfoSectionLayoutId.Stacked},
      {[s.tabs]: theme === InfoSectionLayoutId.Tabs},
      {[s.columns]: theme === InfoSectionLayoutId.Columns},
      infoSectionTitleStylable.root
    );

    return (
      <Text tagName={htmlTags.infoSectionTitleHtmlTag} className={titleClassName} data-hook={DataHook.Title}>
        {title}
      </Text>
    );
  }
);

/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface ArrowRightProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const ArrowRight: React.FC<ArrowRightProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path fill="currentColor" d="M4.48 13.934c-0.214 0.217-0.214 0.566 0 0.783s0.561 0.217 0.775 0l6.264-6.326c0.214-0.217 0.214-0.567 0-0.783l-6.264-6.326c-0.214-0.217-0.561-0.217-0.775 0s-0.214 0.566 0 0.783l5.712 5.934-5.712 5.934z"
    />
  </svg>
);
ArrowRight.displayName = 'ArrowRight';
/* tslint:enable */
/* eslint-enable */

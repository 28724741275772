/* eslint-disable @typescript-eslint/no-implied-eval */
/* istanbul ignore next: todo(sagi) */
export function preventDefault(e: TouchEvent) {
  e.preventDefault();
}

/* istanbul ignore next: todo(sagi) */
export function timeout(fn: Function, ms: number = 200) {
  setTimeout(fn, ms);
}

import React from 'react';
import {CollapseInfoItem} from './CollapseInfoItem/CollapseInfoItem';
import s from './CollapseInfoSection.scss';
import {InfoSectionProps} from '../../InfoSectionLayout';
import {InfoSectionEvent} from '../../../../types/app-types';
import _ from 'lodash';

export enum DataHook {
  CollapseInfoLayout = 'collapse-info-section',
}

interface CollapseInfoSectionState {
  currentActiveCellIndices: number[];
}

export interface CollapseInfoSectionProps extends InfoSectionProps {
  handleBIEvent(BIEvent: InfoSectionEvent): void;
  updateLayout: Function;
}

export class CollapseInfoSection extends React.Component<CollapseInfoSectionProps, CollapseInfoSectionState> {
  private readonly getInitActiveItems = () => {
    const {infoSectionItems} = this.props;
    if (infoSectionItems.length === 2) {
      return [0, 1];
    } else if (infoSectionItems.length > 2 || infoSectionItems.length === 1) {
      return [0];
    } else {
      return [-1];
    }
  };

  public state = {
    currentActiveCellIndices: this.getInitActiveItems(),
  };

  private readonly isNotTwoItems = () => {
    return this.props.infoSectionItems.length !== 2;
  };

  private readonly updateLayout = (ref: React.RefObject<HTMLButtonElement> = null) => {
    this.props.updateLayout && this.props.updateLayout();
    /* istanbul ignore next */
    ref && ref.current.ariaExpanded === 'true' && ref.current.scrollIntoView({block: 'center', behavior: 'smooth'});
  };

  public isItemActive = (index: number) => {
    const {currentActiveCellIndices} = this.state;
    return currentActiveCellIndices.some((cell) => cell === index);
  };

  public handleToggleCollapse = (toggledIndex: number) => {
    let {currentActiveCellIndices} = this.state;
    const {handleBIEvent} = this.props;
    handleBIEvent({index: toggledIndex, type: 'expand'});

    if (this.isItemActive(toggledIndex)) {
      const currentActiveCellIndicesUpdated = _.without(currentActiveCellIndices, toggledIndex);
      this.setState({currentActiveCellIndices: currentActiveCellIndicesUpdated}, this.updateLayout);
    } else {
      if (this.isNotTwoItems()) {
        currentActiveCellIndices = [toggledIndex];
      } else {
        currentActiveCellIndices.push(toggledIndex);
      }
      this.setState({currentActiveCellIndices}, this.updateLayout);
    }
  };

  public render(): JSX.Element {
    const {infoSectionItems} = this.props;
    return (
      <ul className={s.collapse} data-hook={DataHook.CollapseInfoLayout}>
        {infoSectionItems.map((infoItem, index) => {
          return (
            <CollapseInfoItem
              key={infoItem.id}
              description={infoItem.description}
              title={infoItem.title}
              index={index}
              isActive={this.isItemActive(index)}
              handelToggleCollapse={this.handleToggleCollapse}
              onAnimationEnd={this.updateLayout}
            />
          );
        })}
      </ul>
    );
  }
}

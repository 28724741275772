import * as React from 'react';

export enum DataHook {
  emptyImage = 'product-empty-image',
}
export const ProductEmptyImage: React.FunctionComponent = () => (
  <img
    data-hook={DataHook.emptyImage}
    src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
    width="0"
    height="0"
    alt=""
  />
);

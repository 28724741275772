/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface CheckProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const Check: React.FC<CheckProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path fill="currentColor" d="M14 3.333q0.286 0 0.477 0.19t0.19 0.477q0 0.281-0.193 0.474l-8 8q-0.193 0.193-0.474 0.193t-0.474-0.193l-4-4q-0.193-0.193-0.193-0.474 0-0.286 0.19-0.477t0.477-0.19q0.281 0 0.474 0.193l3.526 3.531 7.526-7.531q0.193-0.193 0.474-0.193z"
    />
  </svg>
);
Check.displayName = 'Check';
/* tslint:enable */
/* eslint-enable */

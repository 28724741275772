import React from 'react';
import cx from 'classnames';
import {Navigation} from '../../Navigation/Navigation';
import {Cell} from '../Cell/Cell';
import {LayoutComponentProps} from '../../../types/app-types';
import s from './SimpleLayout.scss';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {ReviewsSlot} from '../../ReviewsSlot/ReviewsSlot';
import {ProductPageTopSlot} from '../../ProductPageTopSlot/ProductPageTopSlot';
import {SimpleLayoutHeader} from './SimpleLayoutHeader';
import {SimpleLayoutLeftSection} from './SimpleLayoutLeftSection';
import {SimpleLayoutRightSection} from './SimpleLayoutRightSection';
import {ProductPageSlotIds} from '../../../constants';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';

export const SimpleLayout: React.FunctionComponent<LayoutComponentProps> = ({settings, globals}) => {
  const {renderProductPageSlots} = globals.experiments ?? {};
  const isPluginInstalledInTopSlot = !!globals.slots?.[ProductPageSlotIds.ProductPageTop];
  const isPluginInstalledInBottomSlot = !!globals.slots?.[ProductPageSlotIds.ProductPageBottom];

  return (
    <article className={s.container}>
      <Cell className={s.width}>
        <div className={cx({[s.topSlot]: isPluginInstalledInTopSlot})}>
          <ProductPageTopSlot />
        </div>
        {settings.shouldShowNavigation && (
          <Navigation
            className={cx({
              [s.navigation]: !isPluginInstalledInTopSlot,
              [s.navigationSmallSpacingTop]: isPluginInstalledInTopSlot,
            })}
          />
        )}
      </Cell>
      <SimpleLayoutHeader settings={settings} globals={globals} />
      <div className={cx(s.width, s.main)}>
        <SimpleLayoutLeftSection globals={globals} settings={settings} />
        <SimpleLayoutRightSection globals={globals} settings={settings} />
      </div>
      {renderProductPageSlots && (
        <Cell className={cx({[s.productPageSlot]: isPluginInstalledInBottomSlot})}>
          <SlotsPlaceholder slotId={ProductPageSlotIds.ProductPageBottom} />
        </Cell>
      )}
      <Cell>
        <ReviewsSlot />
        <BottomPlaceholder />
      </Cell>
    </article>
  );
};

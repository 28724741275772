import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {LazyRichContentViewer} from '../../RichContentViewer/LazyRichContentViewer';
import {SPECS} from '../../../specs';
import {isRicoRichContent} from '../../RichContentViewer/utils';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {InfoSectionDescription as InfoSectionDescriptionOld} from './InfoSectionDescriptionOld/InfoSectionDescription';
import {useStyles} from '@wix/tpa-settings/react';
import stylesParams from '../../../../components/ProductPage/stylesParams';

export enum DataHook {
  Description = 'info-section-description',
}
export interface InfoSectionDescriptionProps extends ProvidedGlobalProps {
  description: string;
  index: number;
}

export const InfoSectionDescription: React.FC<InfoSectionDescriptionProps> = withGlobalProps(
  (props: InfoSectionDescriptionProps) => {
    const styles = useStyles();
    const {description, index} = props;
    const {experiments} = useExperiments();
    const shouldRenderRico =
      experiments.enabled(SPECS.PRODUCT_PAGE_RICO_DESCRIPTION) &&
      (isRicoRichContent(description) || experiments.enabled(SPECS.PRODUCT_PAGE_MIGRATE_TO_RICO_DESCRIPTION));

    if (!shouldRenderRico) {
      return <InfoSectionDescriptionOld description={description} index={index} />;
    }

    const ricoStylesParams = {
      textColor: styles.get(stylesParams.productPage_infoSectionTextColor),
      textFont:
        styles.get(stylesParams.productPage_infoSectionTextFontStyle) ??
        styles.get(stylesParams.const_productPage_BodyM_16px),
      alignment: styles.get(stylesParams.productPage_infoSectionAlignment),
      table: {
        BackgroundColor: styles.get(stylesParams.productPage_infoSectionTableZebraColor1),
        borderColor: styles.get(stylesParams.productPage_infoSectionTableBorderColor),
        evenNumberColor: styles.get(stylesParams.productPage_infoSectionTableZebraColor2),
      },
    };

    return (
      <LazyRichContentViewer
        stylesParams={ricoStylesParams}
        key={index}
        dataHook={DataHook.Description}
        contentAsString={description}
        ricosContext={'InfoSectionDescription'}
      />
    );
  }
);

import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import s from './ProductSku.scss';
import {IOptionSelectionVariant} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export type ProductSkuProps = ProvidedGlobalProps & IProvidedTranslationProps;

export const ProductSku: React.FC<ProductSkuProps> = withGlobalProps(
  withTranslations('globals.texts')((props: ProductSkuProps) => {
    const {t} = props;
    const {product} = props.globals;
    const sku = (props.globals.selectedVariant || ({} as IOptionSelectionVariant)).sku || product.sku;
    const text = sku ? `${t('SKU_LABEL')} ${sku}` : '';

    if (!sku) {
      return null;
    }

    return (
      <div className={s.sku} data-hook="sku">
        {text}
      </div>
    );
  })
);

import * as React from 'react';
import {NavigationArrowButton} from './NavigationArrowButton/NavigationArrowButton';
import {ArrowsDir} from '../../../../constants';

export interface ThumbnailsNavigationProps {
  onNavigation(direction?: ArrowsDir);
  vertical: boolean;
}

export const ThumbnailsNavigation: React.FunctionComponent<ThumbnailsNavigationProps> = ({
  onNavigation,
  vertical,
  children,
}) => {
  return (
    <>
      <NavigationArrowButton
        handleNavigation={onNavigation}
        direction={vertical ? ArrowsDir.UP : ArrowsDir.LEFT}
        vertical={vertical}
      />
      {children}
      <NavigationArrowButton
        handleNavigation={onNavigation}
        direction={vertical ? ArrowsDir.DOWN : ArrowsDir.RIGHT}
        vertical={vertical}
      />
    </>
  );
};

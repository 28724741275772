import React, {Component} from 'react';

export interface ForwardRefProps {
  forwardedRef: React.RefObject<any>;
  ref?: React.RefObject<any>;
}

export const WithRef: React.Component | any = (WrappedComponent) => {
  class CompWithRef extends Component<ForwardRefProps> {
    public render(): JSX.Element {
      const {forwardedRef, ...rest} = this.props;
      return <WrappedComponent forwardedRef={forwardedRef} {...rest} />;
    }
  }

  return React.forwardRef((props: any, ref: React.RefObject<any>) => {
    return <CompWithRef {...props} forwardedRef={ref} />;
  });
};
